.product-single-meta {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.product-info {
    flex: 1; /* Main product information takes most of the space */
}

.products-included {
    flex: 0 0 30%; /* Adjust width as needed */
    max-width: 300px; /* Ensures it does not take up more space */
    margin-left: auto; /* Pushes it to the right */
}
