
.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.desktop-image {
  display: block;
}

.mobile-image {
  display: none;
}

@media (max-width: 767px) {
  .desktop-image {
    display: none;
  }

  .mobile-image {
    display: block;
  }
}

.whatsapp-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: green;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 999;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}