.order-success-container {
    max-width: 600px;
    margin: 0 auto;
    text-align: center;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  }
  
  .order-success-heading {
    font-size: 2em;
    color: #333;
  }
  
  .order-success-text {
    font-size: 1.2em;
    color: #666;
    margin-bottom: 15px;
  }
  