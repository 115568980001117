/* Calendar date highlighted in red */
.highlight-red {
    background-color: rgba(255, 0, 0, 0.3); /* Red shade with opacity */
    color: #ffffff; /* Optional: Set text color to white for contrast */
    border-radius: 50%; /* Optional: Make the highlight rounded */
  }
  

/* Available dates */
.highlight-green {
    background-color: #ccffcc !important;
    color: #006600 !important;
}